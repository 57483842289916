import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Routes from "./routes/Routes";
import {socket} from "api";

import {useSocket} from "api/socket";

import "App.css";

import {crudFunctions} from "api";
import {SOLICITUD} from "api/TYPES";
import {useDialog} from "Contexts/DialogContext";
import Solicitud from "pages/Solicitudes/Solicitud";


console.log({openpay: process.env.REACT_APP_OPENPAY_ID, env:process.env.NODE_ENV})
if(window.OpenPay){
	window.OpenPay.setId(process.env.REACT_APP_OPENPAY_ID);
	window.OpenPay.setApiKey(process.env.REACT_APP_OPENPAY_PUBLIC_KEY);
	window.OpenPay.setSandboxMode(process.env.NODE_ENV!=="production");
}
// import "./App.css";
require("analytics");
function App() {
	const [openDialog, closeDialog] = useDialog();
	
	const actions = crudFunctions(SOLICITUD);

	React.useEffect(()=>{
		actions.getAll().then(solicitudes=>{
			solicitudes.filter(s=>!s._trash).map(solicitud=>{
				openModalSolicitud(solicitud)
			});
		});
	},[]);
	useSocket({name: "nueva_solicitud", onEvent:data=>{
		openModalSolicitud(data);
	}});
	const openModalSolicitud = (solicitud) => {
		openDialog({
			Content: Solicitud,
			data:solicitud,
			closeModal:closeDialog,
			disableBackdropClick:true
		});
	}
	return (
		<Router>
			<Switch>
				<Routes />
			</Switch>
		</Router>
	);
}

export default App;
